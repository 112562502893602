<template>
  <section id="settingsProfile">
    <b-overlay
      :show="show"
      variant="transparent"
      :opacity="1.00"
      :blur="'2px'"
      rounded="lg"
    >
      <b-row>
        <b-col style="text-align: start;">
          <h1>{{ $t('Avatares') }}</h1>
          <h5 class="text-primary">
            {{ $t('selAvatar') }}
          </h5>
        </b-col>
        <b-col class="d-flex justify-content-end">
          <div class="m-2">
            <b-button
              :disabled="selected.length > 0 ? false : true"
              variant="success"
              @click="saveSelected"
            >
              {{
                $t("dataGeneric.save")
              }}
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-input
            v-model="search"
            :disabled="selected.length == 0 ? false : true"
            :placeholder="$t('Search') + '...'"
            @change="onKeyUp"
          />
        </b-col>
        <b-col md="2">
          <b-button
            variant="primary"
            :disabled="selected.length == 0 ? false : true"
            @click="onKeyUp"
          >
            {{ $t('Search') }}
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="d-flex justify-content-end mt-2">
          <span>
            <strong>{{ characters.length }}</strong> {{ $t("contents.elements") }}
          </span>
        </b-col>
      </b-row>
      <b-row class="match-height mt-2">
        <b-col>
          <b-card
            style="height: 35rem;
    overflow: auto;"
          >
            <b-card-body>
              <b-row v-if="characters.length > 0">
                <b-col
                  v-for="i in characters"
                  :key="i.node.id"
                  md="2"
                  class="m-1 cursor-pointer"
                  @click="selectAvatar(i.node.id)"
                >
                  <div class="profile-pic">
                    <b-avatar
                      :id="i.node.id"
                      size="6em"
                    >
                      <b-img
                        :src="i.node.image"
                        class="image_avatar"
                        rounded="circle"
                      />
                    </b-avatar>
                  </div>
                  <div class="mt-25">
                    <small :class="selected.includes(i.node.id) ? 'text-primary' : ''">{{ i.node.name }}</small>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </section>
</template>
<script>

import {
  BButton,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BImg,
  BFormInput,
  BOverlay,
  BAvatar,
  VBTooltip,
} from 'bootstrap-vue'
import { showToast, base64Encode, messageError } from '@/store/functions'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { getUserData } from '@/auth/utils'

const fileError = require('@/assets/images/backend/file-error.png')

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BAvatar,
    BImg,
    BFormInput,
    BOverlay,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    old: {},
  },
  data() {
    return {
      show: false,
      id: null,
      name: '',
      description: '',
      imageShow: '',
      imageSrc: null,
      imageBol: false,
      imageFile: null,
      search: '',
      contentsTags: [],
      isLoading: false,
      headers: {},
      userData: getUserData(),
      hasMore: true,
      contents: [],
      categories: [],
      categoriesTags: [],
      page: 1,
      nameState: null,
      selected: [],
      idTags: [],
      originalTags: [],
      imageState: null,
      characters: [],
    }
  },
  watch: {
    contentsTags() {
      this.contents = this.contents.filter(item => this.contentsTags.includes(item.tag))
    },
    categoriesTags() {
      this.categories = this.categories.filter(item => this.categoriesTags.includes(item.tag))
    },
    imageFile(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.imageBol = true
              this.imageSrc = value
            })
            .catch(() => {
              this.imageSrc = null
            })
        } else {
          this.imageSrc = null
        }
      }
    },
  },
  mounted() {
    let defaultLang = 'es'
    try {
      defaultLang = this.userData.profile.client.defaultLanguage
      // eslint-disable-next-line no-empty
    } catch (error) { }

    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }
    this.getCharacters()
    const badge = document.getElementsByClassName('b-avatar-badge')
    badge.forEach(element => {
      // eslint-disable-next-line no-param-reassign
      element.style = 'font-size: calc(0.80em);  top: 0.5em; right: 0.5em;'
    })
  },
  methods: {
    saveSelected() {
      this.show = true
      this.$emit('confirm', { data: this.selected })
    },
    selectAvatar(id) {
      const index = this.selected.indexOf(id)
      if (index === -1) { this.selected.push(id) } else this.selected.splice(index, 1)
      document.getElementById(id).classList.toggle('profile-selected')
    },
    onKeyUp() {
      this.characters = []
      this.getCharacters()
    },
    getCharacters() {
      this.show = true
      const { headers } = this
      const userData = getUserData()
      axios
        .post('', {
          query: `{
            allCharacter(
            name:"${this.search}",client:"${userData.profile.client.id}") {
              totalCount
              edges {
                node {
                  id
                  name
                  description
                  image                  
                  tags {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                  contents {
                    edges {
                      node {
                        id
                        name
                        
                      }
                    }
                  }
                  categories {
                    edges {
                      node {
                        id
                        name
                        
                      }
                    }
                  }
                }
              }
            }
          }`,
        }, { headers })
        .then(result => {
          messageError(result, this)

          const reSearch = result.data.data.allCharacter.edges

          const selectedCharacters = []
          this.old.forEach(element => {
            selectedCharacters.push(element.node.character.id)
          })
          const srch = reSearch.filter(objeto => !selectedCharacters.includes(objeto.node.id))
          this.characters = srch
          this.show = false
        })
        .catch(err => {
          console.log(err)
        })
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.id !== null) { this.updateCharacter() } else this.createCharacter()
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      let validate = true
      if (this.name.trim().length === 0) {
        validate = valid
        this.nameState = valid
      } else { this.nameState = true }
      if (!this.imageBol) {
        validate = valid
        this.imageState = valid
      } else this.imageState = true

      return validate
    },
    createCharacter() {
      if (!this.checkFormValidity()) {
        return
      }
      this.show = true
      const userData = getUserData()
      const data = new FormData()
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const query = `
          mutation($name: String!,$description:String,$client:ID!,$tags: [ID]
          ,$contents: [ID],$categories: [ID]){
            createCharacter(input:{name:$name,
              description:$description,
              client:$client,
              tags:$tags,
              contents:$contents,
              categories:$categories
              }){
              character {
                id
                name
                
              }
            }
          }`
      const variables = {
        name: this.name,
        description: this.description,
        client: userData.profile.client.id,
        tags: [...this.idTags],
        contents: this.formatObject(this.contents),
        categories: this.formatObject(this.categories),
      }
      data.append('query', query)
      data.append('variables', JSON.stringify(variables))
      data.append('image', this.imageFile)
      axios
        .post('', data, config)
        .then(result => {
          messageError(result, this)
          this.$nextTick(() => {
            this.$bvModal.hide('modal-prevent-closing')
          })
          showToast(this.$t('code.updateData'), 1, this)

          this.getCharacters()
        })
        .catch(err => {
          showToast(this.$t('code.updateDataError'), 2, this)

          console.log(err)
        })
    },
    formatObject(object) {
      const array = []
      object.forEach(element => {
        array.push(element.id)
      })
      return array
    },
    updateCharacter() {
      if (!this.checkFormValidity()) {
        return
      }
      this.show = true
      const data = new FormData()
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const query = `
          mutation($name: String!,$description:String,$tags: [ID],
          $contents: [ID],$categories: [ID]){
            updateCharacter(id:"${this.id}",input:{
              name:$name,
              description:$description,
              tags:$tags,
              contents:$contents,
              categories:$categories,
              }){
              character {
                id
                name
                
              }
            }
          }`
      const variables = {
        name: this.name,
        description: this.description,
        tags: [...this.idTags],
        contents: this.formatObject(this.contents),
        categories: this.formatObject(this.categories),
      }
      data.append('query', query)
      data.append('variables', JSON.stringify(variables))
      if (this.imageFile) data.append('image', this.imageFile)

      axios
        .post('', data, config)
        .then(result => {
          messageError(result, this)
          this.$nextTick(() => {
            this.$bvModal.hide('modal-prevent-closing')
          })
          showToast(this.$t('code.updateData'), 1, this)
          this.getCharacters()
        })
        .catch(() => {
          showToast(this.$t('code.updateDataError'), 2, this)
        })
    },
    errorImg(e) {
      e.target.src = fileError
    },
    handleSubmit(bvModalEvt) {
      this.handleOk(bvModalEvt)
    },

    chooseInput(dato) {
      document.getElementById(dato).click()
    },
    resetModal() {
      this.name = ''
      this.id = null
      this.description = ''
      this.nameState = null
      this.imageState = null
      this.show = false
    },
  },
}
</script>
<style lang="scss" scoped>
.card-body {
  padding: 1rem;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 1px;
}

.card-body h4 {
  font-size: 1.286rem !important;
}

.col-form-label {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.fly-image-input {
  max-width: 100%;
}

.base-color-input {
  display: block;
  border-radius: 15px;
  border: solid 1px;
  border-color: white;
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.base-card-input {
  display: block;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

.div {
  display: inline-block;
}

.inputColor {
  visibility: hidden;
}

.placeholder_photo {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

.placeholder_photo:hover {
  background: #e0e0e0;
}

.file-input {
  display: none;
}

.cBox {
  align-items: center;
}

.custom-control-label {
  font-size: 15px;
}

.scroll {
  overflow: hidden;
  flex-wrap: nowrap !important;

}

.list {
  overflow: hidden;
  overflow-y: scroll;
  height: 380px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  display: none;
}

.contain {
  object-fit: contain;
  cursor: pointer;
}

.image_avatar {
  /* object-fit: scale-down; */
  max-width: initial;
  max-height: max-content;
  height: 100%;
  width: 100%;
}

.editImage {
  position: absolute;
  display: none;
}

.profile-pic:hover .editImage {
  display: block;
}

.profile-pic:hover .image_avatar {
  opacity: 30%;
}

.profile-selected {
  opacity: 50%;

}

.profile-pic {
  position: relative;
  display: inline-block;
}

.edt:hover {
  color: rgb(0, 150, 13);
  cursor: pointer;
}

.del:hover {
  color: #a60303;
  cursor: pointer;
}
</style>
